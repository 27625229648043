<template>
	<div id="cpm_liveType">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input-search
                    v-model:value="acState.search.key"
                    placeholder="分类名称"
                    style="width: 400px;"
                    enter-button
                    @search="getAdoptCategory(1,acState.limit)"
                />
				<a-button type="primary" v-has="{action:'wxapp_live_type_add',plat:isShop}" @click="showEditCategory(0)">
					<i class="ri-add-line"></i>新增分类
				</a-button>
				<a-button type="danger" v-has="{action:'wxapp_live_type_del',plat:isShop}" @click="deleteAdoptCategory(acState.pks)">
					<i class="ri-delete-bin-line"></i>批量删除
				</a-button>
			</a-space>
		</div>


		<div class="el-content">
			<a-table :data-source="acState.list" :pagination="false" row-key="id" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'分类名称',dataIndex:'name'},
				{title:'排序',dataIndex:'rank'},
				{title:'是否显示',dataIndex:'is_show',slots:{customRender:'is_show'}},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" :row-selection="{ selectedRowKeys: acState.pks, onChange:(e)=>{ acState.pks = e}  }">
				<template #is_show="{record}">
					<a-tag :color="record.is_show == 1 ?'#87d068':'#999'">
						{{record.is_show == 1 ?'显示':'隐藏'}}
					</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button title="编辑"
                                   type="primary"
                                   icon="ri-edit-line"
                                   v-has="{action:'wxapp_live_type_add'}"
                                   @click="showEditCategory(record)">
                        </kd-button>
						<kd-button title="删除"
                                   type="danger"
                                   icon="ri-delete-bin-5-line"
                                   v-has="{action:'wxapp_live_type_del'}"
                                   @click="deleteAdoptCategory(record.id)">
                        </kd-button>
					</a-space>
				</template>
			</a-table>

			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="acState.page"
					:total="acState.count"
					@showSizeChange="(p,e)=>{getAdoptCategory(acState.page,e)}"
					@change="(e)=>{getAdoptCategory(e,acState.limit)}"
				/>
			</div>
		</div>
		<a-modal title="认养分类"
                 v-model:visible="eacState.show"
                 @ok="saveAdoptCategory"
                 @cancel="eacState.show = false"
                 width="600px" >
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
                <a-form-item label="分类名称">
                    <a-input v-model:value="eacState.form.name"></a-input>
                </a-form-item>
                <a-form-item label="排序" >
                    <a-input v-model:value="eacState.form.rank"></a-input>
                </a-form-item>
            </a-form>
		</a-modal>
	</div>
</template>

<script>
import { useAdoptCategory,useEditAdoptCategory } from '@/models/adopt'
export default{
	name:'page-adopt-type',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
        let { acState,getAdoptCategory,deleteAdoptCategory } = useAdoptCategory()
        getAdoptCategory(1,acState.limit)

        let eacState = useEditAdoptCategory(acState,getAdoptCategory)
		return {
            acState,
            getAdoptCategory,
            deleteAdoptCategory,
            ...eacState
		}
	}
}
</script>
<style lang="scss">
</style>
